body{
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}
iframe {
  z-index: -1!important;
}
.counter-item {
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
}
.counter-item:hover {
  color: #fff;
  background-color: #000;
}
.counter-number {
  font-size: 1.5rem;
}
.container{
  height: 100vh;
  width: 100vw;
  padding-top: -5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mint-box {
  margin-top: -15rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}
@media screen and (max-width: 649px) {
  .mint-box {
    max-width: 90%;
  }
}
@media screen and (min-width: 650px) {
  .mint-box {
    min-width: 605px;
  }
}
.connect-wallet {
}
.flex {
  display: flex;
  margin-top: 4rem;
}
.s-background {
  background-image: url("./assets/img/mint_page_background.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.mint-btn span {
  position: relative;
  font-size: 1.1rem;
  color: #04e0fa;
  padding: 5px 20px;
  position: relative;
  display: flex;
  z-index: 10;
  background-color: #000;
  border-radius: 10px;
}
.mint-btn {
  font-size: 0.75rem;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
.back-link {
  text-decoration: none;
  
}
.back-link div div span {
  color: #fff;
  font-size: 1.25rem!important;
}
.white-border {
  border: 1px solid #fff;
}
.mint-btn div span:hover {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.mint-container {
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
}
.mint-price {
  padding: 10px 0;
  color: #fff;
  font-size: large;
  display: flex;
  align-items: center;
}
.eth-icon {
  margin: 0 5px;
}
.mint-text {
  padding: 10px 0;
  color: #fff;
  font-size: x-large;
}
.countdown {
  display: flex;
  color: #fff;
}
.countdown span {
  padding: 0.5rem;
}
.countdown-box {
  padding: 0.5rem;
}
@media screen and (min-width: 650px) {
  .countdown-text{
    font-size: 1.5rem;
  }
  .countdown {
    flex-direction: row;
  }
}
@media screen and (max-width: 649px) {
  .countdown {
    text-align: center;
    flex-direction: column;
  }
}

.w-half {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.counter {
  color: #FFF;
}
@media (min-width: 1440px) {
  .mint-btn span {
    font-size: 1.175rem;
  }
}
.name-wrapper div {
  display: block;
}
.name-wrapper {
  cursor: pointer;
  position: relative;
}

@media (min-width: 1440px) {
  .name-wrapper::after {
    width: calc(100% + 40px);
    padding-top: 45px;
    left: -20px;
    top: 0px;
  }
}
a.subscribe {
  color: #ffffff!important;
  background: #44c761;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  max-width: 70vw;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  overflow: hidden;
  transition: .5s;
  margin-top: 20px;
  border-radius: 8px;
  color: #000;
  font-weight: bold;

}
a.subscribe:hover {
  box-shadow:0 0 2px #44c761,
      0 0 5px #44c761,
      0 0 10px #44c761,
      0 0 50px #44c761;
    
}